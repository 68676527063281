import { useEffect } from "react";

const AnalyticsWrapper = () => {
    useEffect(() => {
        const uaid = 'G-PQZVNGC5XK';
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + uaid;
        document.getElementsByTagName('head')[0].appendChild(script);

        function gtag() { 
            var dataLayer = window.dataLayer = window.dataLayer || [];
            dataLayer.push(arguments); 
        }
       
        gtag('js', new Date());
        gtag('config', uaid);
        gtag("consent", "default", {
            ad_storage: "denied",
            ad_user_data: "denied", 
            ad_personalization: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 2000,
        });
        gtag("set", "ads_data_redaction", true);
        gtag("set", "url_passthrough", true);
    }, []);
  
    return null;
  };
  
  export default AnalyticsWrapper;